<template>
  <div>
    <van-nav-bar
      :title="navbarTitle"
      left-text=""
      left-arrow
      :fixed="true"
      @click-left="back"
    >
    </van-nav-bar>
    <div id="content">
      <ul class="selet">
        <li @click="toLanguage">
          <span class="text">{{ $t("mine.Language") }}</span>
          <span class="arrow">
            <span style="padding-right: 5px">{{ choosedLanguage }}</span>
            >
            </span>
        </li>
        <li @click="routerClick">
          <span class="text">{{ $t("mine.ResetPassword") }}</span>
          <span class="arrow">></span>
        </li>
        <li @click="logoutClick">
          <span class="text">{{ $t("mine.Loginout") }}</span>
          <span class="arrow">></span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      sure: "",
      navbarTitle: "",
      choosedLanguage: "",
      chinese: false,
      english: false,
      message: "Lorem ipsum dolor sit amet ",
    };
  },
  created() {
    this.navbarTitle = this.$t("mine.Settings");
  },
  methods: {
    logoutClick() {
      this.$dialog.confirm({
        title: "",
        message: this.$t("mine.Loginout_wran"),
        confirmButtonText:this.$t('stringCode.Done'),
        cancelButtonText:this.$t('stringCode.Cancel')
      })
        .then(() => {
          window.localStorage.clear();
          this.$router.push('/login')
        })
        .catch(() => {
          // on cancel
        });
    },
    routerClick() {
      // Y 表示是由应用设置页面跳转的
      this.$router.push({
        path: "resetVerify",
        query: {
          type: "Y",
        },
      });
    },
    back() {
      this.$router.push("/mine");
    },
    toLanguage() {
      this.$router.push({
        path: "/language",
      });
    },
    chooseChinese() {
      this.chinese = true;
      this.english = false;
    },
    chooseEnglish() {
      this.chinese = false;
      this.english = true;
    },
  },
};
</script>
<style scoped>
.van-nav-bar {
  background: #fafafa;
}
#content {
  background: #fafafa;
  padding: 0;
  height: calc(100vh - 60px);
  box-sizing: border-box;
}
.van-nav-bar__content {
  height: 60px;
}
.van-nav-bar__title {
  font-weight: bold;
}
.selet li {
  background: #fff;
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  /* line-height: 56px; */
}
.selet .text {
  color: #333333;
  font-size: 14px;
}
.selet .sureIcon {
  font-size: 14px;
  color: #2dd070;
}
</style>
